import React from "react";
import { shape } from "prop-types";
import styled from "styled-components";
import { Row, Col} from "react-bootstrap";
import { graphql } from 'gatsby'
import { SubHeader } from "../styles/styles";
import Layout from "../components/Layout";
import Sections from "../contentful/Sections";
import Usps from "../contentful/Usps";

const Text = styled.p`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  line-height: calc(1ex / 0.32);
`;

const projects = ({ data }) => {
    return (
      <Layout>
        <div className="mt-5" />
        <Sections layouts={data.contentfulPageContainer.layouts} />
        <Row className="justify-content-around">
          <Col xs={12}>
            <SubHeader>Technologies</SubHeader>
          </Col>
          {data.allContentfulLayoutUspsItems.edges.map((edge) => (
            <Col xs={12} md={5} key={edge.node.title}>
              <>
                <Text className="text-center">{edge.node.title}</Text>
                <Usps items={edge.node.items} />
              </>
            </Col>
          ))}
        </Row>
      </Layout>
    );
};
projects.defaultProps = {
  data: {}
};

projects.propTypes = {
  data: shape()
};

export default projects;


export const projectsQuery = graphql`
  query projectsQuery {
    allContentfulLayoutUspsItems(filter: { slug: { eq: "projects" } }) {
      edges {
        ...uspsFragment
      }
    }
    contentfulPageContainer(slug: { eq: "projects" }) {
      layouts {
        ... on ContentfulLayoutNameDescription {
          ...portfolioDescriptionFragment
        }
        ... on ContentfulLayoutProjectDescription {
          ...projectFragment
        }
      }
    }
  }
`;